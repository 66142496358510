<template>
  <div class="admin-exceptions">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="goBack"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        {{ title }}
      </h1>
      <div class="actions" v-if="presence">
        <a-button
          @click.prevent="loadExceptions"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="quickCreateException"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Quick Create</a-button
        >

        <a-button
          @click.prevent="createException"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- / Header -->

    <!-- Description -->
    <a-alert
      style="margin-bottom: 25px"
      message="Exceptions let you schedule a user to be available or unavailable for a specific period of time"
    >
    </a-alert>
    <!-- / Description -->

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- No results -->
      <a-alert
        v-if="!exceptions.length"
        type="info"
        message="No exceptions to show here. You can create one using the button above."
      >
      </a-alert>
      <!-- / No results -->

      <!-- {{ exceptions }} -->

      <!-- Results -->
      <a-table
        v-if="exceptions.length"
        :columns="columns"
        :data-source="exceptionsForTable"
        :pagination="false"
        bordered
      >
        <div slot="actions" slot-scope="text, record">
          <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteException(record)"
          >
            <a-button type="danger" icon="delete">Delete</a-button>
          </a-popconfirm>
        </div>
      </a-table>
      <!-- / Results -->
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import presences from "../../../../api/presences";
import availability from "../../../../api/availability";
const _ = require("lodash");
// import rotaHelpers from "../../../../helpers/rota";
import LoadingScreen from "../../../../components/LoadingScreen.vue";

const COLUMNS = [
  {
    title: "Status",
    dataIndex: "statusHuman",
  },
  {
    title: "From",
    dataIndex: "fromHuman",
  },
  {
    title: "Until",
    dataIndex: "untilHuman",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    scopedSlots: { customRender: "actions" },
    width: 100,
  },
];

export default {
  data() {
    return {
      isLoadingPresence: false,
      presence: null,

      isLoadingExceptions: false,
      exceptions: [],

      columns: COLUMNS,

      isDeleting: false,
    };
  },
  components: { LoadingScreen },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadPresence();
      this.loadExceptions();
    }
  },
  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    isLoading() {
      return this.isLoadingPresence || this.isLoadingExceptions;
    },

    title() {
      if (this.isLoadingPresence) {
        return "Loading...";
      }
      if (this.presence) {
        return "Exceptions For " + this.presence.displayName;
      }
      return null;
    },

    presenceId() {
      return this.$route.params.presenceId;
    },

    exceptionsForTable() {
      return _.map(this.exceptions, (exception) => {
        let statusHuman = "Unavailable";
        if (exception.available) {
          statusHuman = "Available";
        }

        let fromHuman = window
          .moment(exception.startInstant)
          .format("ddd Do YYYY, HH:mm");
        let untilHuman = window
          .moment(exception.endInstant)
          .format("ddd Do YYYY, HH:mm");

        return {
          statusHuman,
          fromHuman,
          untilHuman,
          ...exception,
        };
      });
    },
  },
  methods: {
    goBack() {
      this.$router.push("/admin/responders/availability?reset=false");
    },

    loadPresence() {
      let vm = this;
      vm.isLoadingPresence = true;
      vm.presence = null;
      presences
        .getPresence(this.tenantId, this.presenceId)
        .then((r) => {
          vm.isLoadingPresence = false;
          vm.presence = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingPresence = false;
          vm.$message.error("Error loading user");
          vm.goBack();
        });
    },

    loadExceptions() {
      let vm = this;
      vm.isLoadingExceptions = true;
      vm.exceptions = [];
      availability
        .getScheduleOverridesForPresence(this.tenantId, this.presenceId)
        .then((r) => {
          vm.exceptions = r.data;
          vm.isLoadingExceptions = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingExceptions = false;
          vm.$message.error("Error loading exceptions");
          vm.goBack();
        });
    },

    quickCreateException() {
      this.$router.push(
        "/admin/responders/availability/" +
          this.presenceId +
          "/exceptions/quick-create"
      );
    },

    createException() {
      this.$router.push(
        "/admin/responders/availability/" +
          this.presenceId +
          "/exceptions/create"
      );
    },

    deleteException(exception) {
      let vm = this;
      vm.isDeleting = true;
      availability
        .deleteOverride(this.tenantId, exception.id)
        .then(() => {
          vm.isDeleting = false;
          vm.loadExceptions();
        })
        .catch((e) => {
          console.log(e);
          vm.isDeleting = false;
          vm.$message.error("Error deleting exception");
        });
    },
  },
};
</script>

<style lang="scss">
.admin-exceptions {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .ant-table {
    background: #fff !important;
  }

  .button-margin-left {
    margin-left: 15px;
  }
}
</style>